// // Fonts
// @import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Datatables
@import '~datatables.net-bs4/css/dataTables.bootstrap4.css';

@font-face {
    font-family: 'Open Sans';
    src: url("/OpenSans-Regular.ttf");
}

body {
  font-family: 'Open Sans', sans-serif !important;
}

.bsbigbox {
  width:100px;
}
.bsbiggerbox {
  width:500px;
}
.wrapper {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.signature-pad {
  width:300px;
  height:150px;
  background-color: white;
}

.driver-signature-pad {
  width:300px;
  height:150px;
  background-color: white;
}

.installer-signature-pad {
  width:300px;
  height:150px;
  background-color: white;
}

.recipient-signature-pad {
  width:300px;
  height:150px;
  background-color: white;
}

.navbar-mkm-yellow {
    background-color: #f7e401;
}

.navbar-mkm-yellow .navbar-toggler {
    background-color:#c5b600;
}
#logout-link {
  cursor: pointer;
}
